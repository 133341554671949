
import { CurrentUser } from '@/types';
import { Keyboard } from '@capacitor/keyboard';
import Vue from '@/vueTyped';
import TheAccountNavigation from '@/components/TheAccountNavigation.vue';
import UserAvatar from '@/components/UserAvatar.vue';
import AppBanner from '@/components/mobile/AppBanner.vue';
import LanguageCallout from '@/components/LanguageCallout.vue';
import TheLanguageSelect from '@/components/TheLanguageSelect.vue';
import ReleaseInfo from '@/components/ReleaseInfo.vue';
import Onboarding from '@/components/Onboarding.vue';
import HistoricalWeatherPrompt from '@/components/HistoricalWeatherPrompt/Index.vue';
import { trackActionsPageEvent } from '@/tracking';
import { TranslateResult } from 'vue-i18n';
import SurveyRequestsPromptBanner from '@/components/Survey/RequestsPromptBanner.vue';
import PrimaryLogo from '@/components/logo/Primary.vue';

export default Vue.extend({
    components: {
        TheAccountNavigation,
        UserAvatar,
        TheLanguageSelect,
        AppBanner,
        LanguageCallout,
        ReleaseInfo,
        Onboarding,
        HistoricalWeatherPrompt,
        SurveyRequestsPromptBanner,
        PrimaryLogo,
    },
    data() {
        return {
            activeTab: null,
            showUserMenu: false,
            showMore: false,
            weatherPromptHeight: 0,
            prevRoute: null,
            keyboardVisible: false,
            keyboardListenerHandles: null as any[] | null,
        };
    },
    computed: {
        platform(): string {
            return this.$store.state.platform;
        },
        isApp(): boolean {
            return ['ios', 'android'].includes(this.platform);
        },
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },
        userChecked(): boolean {
            return this.$store.state.account.autoAuthStatus !== null;
        },
        headingTitle(): TranslateResult {
            if (this.$route.name && this.$route.name !== 'home') {
                // vue-i18n doesn't allow dots in keys.
                const routeWithoutDots = this.$route.name.split('.').join('_');
                if (this.$te(`routes.${routeWithoutDots}`)) {
                    return this.$t(`routes.${routeWithoutDots}`);
                } else {
                    const routePrefix = this.$route.name.split('.')[0];
                    if (this.$te(`routes.${routePrefix}`)) {
                        return this.$t(`routes.${routePrefix}`);
                    }
                }
            }
            return '';
        },
        hideMainNav(): boolean {
            return !this.currentUser && this.$route.matched.some(record => record.meta.hideMainNavWithNoUser);
        },
        showBottomNavigation(): boolean {
            return Boolean(!this.hideMainNav && this.$route && (this.$route.meta && !this.$route.meta.hideMobileNav) && !this.keyboardVisible);
        },
        showClientLinks(): boolean {
            return (this.currentUser?.clientGroups?.length ?? 0) !== 0;
        },
        iconDestination() {
            if (this.$route.meta?.baseRoute) {
                return { name: this.$route.meta.baseRoute };
            } else if (this.platform !== 'web') {
                // Posts are the home of the app.
                return { name: 'posts' };
            } else {
                return { name: 'home' };
            }
        },
        iconGoesBack(): boolean {
            return Boolean(this.$route.meta?.baseRoute);
        },
        forceOnboardingToShow(): boolean {
            return location.search.includes('show-mobile-onboarding');
        },
    },
    watch: {
        $route() {
            this.showMore = false;
        },
        currentUser() {
            this.showUserMenu = false;
        },
        userChecked() {
            // TODO: Call this when onboarding can show up without the rest of the UI (refactor it to not be a dialog).
            // For now it'll time out on its own.
            // SplashScreen.hide();
        },
    },
    async mounted() {
        document.documentElement.dataset.mobileLayout = '1';

        window.addEventListener('keydown', this.handleKeydown);
        window.addEventListener('focus', this.scrollToFocusedElementIfNecessary, true);

        try {
            await Keyboard.setAccessoryBarVisible({ isVisible: true });

            this.keyboardListenerHandles = [
                await Keyboard.addListener('keyboardDidShow', this.handleKeyboardShow),
                await Keyboard.addListener('keyboardDidHide', this.handleKeyboardHide),
            ];
        } catch (error: any) {
            if (!`${error?.message}`?.toUpperCase().includes('NOT IMPLEMENTED')) {
                throw error;
            }
        }
    },

    destroyed() {
        delete document.documentElement.dataset.mobileLayout;

        window.removeEventListener('keydown', this.handleKeydown);
        window.removeEventListener('focus', this.scrollToFocusedElementIfNecessary, true);
        this.keyboardListenerHandles?.forEach(handle => handle?.remove());
    },

    methods: {
        handleKeydown(event: KeyboardEvent) {
            if (event.key === 'Escape') {
                this.showUserMenu = false;
                this.showMore = false;
            }
        },

        async handleKeyboardShow() {
            this.keyboardVisible = true;
            await this.$nextTick();
            window.dispatchEvent(new Event('resize'));
            setTimeout(() => {
                // If a field in an iframe is focused, the iframe becomes this document's activeElement, so don't do anything.
                if (document.activeElement instanceof HTMLIFrameElement) return;
                this.scrollToFocusedElementIfNecessary({ target: document.activeElement } as FocusEvent);
            }, 250);
        },

        async handleKeyboardHide() {
            this.keyboardVisible = false;
            await this.$nextTick();
            window.dispatchEvent(new Event('resize'));
        },

        scrollToFocusedElementIfNecessary(event: FocusEvent) {
            // When focusing an element, it might scroll into the viewport under a floating header or footer.
            // Let's move it to the center of the screen if it's obscured.
            const eventTarget = event.target;
            if (eventTarget instanceof Element && eventTarget.tagName !== 'A') {
                // Give the UI a bit to settle down.
                setTimeout(() => {
                    const bounds = eventTarget.getBoundingClientRect();
                    const centerX = bounds.left + bounds.width / 2;
                    const centerY = bounds.top + bounds.height / 2;
                    const topElementAtCenter = document.elementFromPoint(centerX, centerY);
                    const targetIsObscured = topElementAtCenter !== eventTarget && !eventTarget.contains(topElementAtCenter);
                    const obscuredByOverlay = topElementAtCenter?.classList.contains('overlay');
                    if (targetIsObscured && !obscuredByOverlay) {
                        try {
                            eventTarget.scrollIntoView({ block: 'center', behavior: 'smooth' });
                        } catch (error) {
                            // The element is probably not there anymore.
                        }
                    }
                });
            }
        },

        trackActionsPageLinkClick() {
            trackActionsPageEvent('Clicked Actions Page Mobile Nav Link');
        },
    }
});
